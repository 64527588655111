import * as React from 'react'
import { Link } from 'gatsby'
let Scroll = require('react-scroll');
let scroller = Scroll.scroller;
var Element = Scroll.Element;

import OfferForm from "../components/OfferForm";
const img = require('../../assets/images/slider/v7_07.jpg')

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import styled from "@emotion/styled";
const P = styled.p`
font-family: ChaparralPro-Light, serif;
font-weight: normal;
font-size: 1.1em;
letter-spacing: 0.1em;
color: #333;
padding-bottom: 2.5em;
line-height: 1.5em;`


const Flex = styled.div`
width:100%;
display:flex;
justify-content:space-between;


 @media screen and (max-width: 870px) {
 display:block;
 }
`

const Img = styled.img`

height:805.98px;
margin-left:24px;


 @media screen and (max-width: 870px) {
 margin-top:6em;
width:100%;
height:auto;
margin-left:0px;
 }
`

const Div=styled.div`
  @media screen and (max-width: 870px) {
text-align:center; }
`

const Desk = styled.div`
  @media screen and (max-width: 870px) {
display:none; }
`

class Services extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }
  componentDidMount() {
    scroller.scrollTo('header', {
      duration: 1500,
      smooth: true,
    })
  }


  render() {
    return (
  <IndexLayout>
    <Page>
      <Container>
        <Element id={'header'}/>
        <Desk>
          <br/>
        </Desk>
        <Div>
        <h1>Sie konzentrieren sich auf ihr Geschäft – Wir kümmern uns um die Details</h1>
        </Div>
        <br/>
        <br/>
        <P>
          Wir bieten Ihren Kleinteams (max. 5 Personen) die Möglichkeit, in unseren separat zugänglichen,
          exklusiven 17-25 qm Büroeinheiten entspannt weiterzuarbeiten.</P>

        <h2>Bei uns stimmt nicht nur das Ambiente, sondern auch der Service</h2>
        <P>Erstklassiges Ambiente verdient auch einen erstklassigen Service. Deshalb ist unser Team hochmotiviert, Ihre Anforderungen und Wünsche zu erfüllen.
          Und damit Sie sich rundum wohlfühlen und entspannt arbeiten können, bieten wir Ihnen folgende Services:</P>

        <h2>Private Offices</h2>
        <P>Sie möchten weder im Großraumbüro sitzen und Homeoffice im Team ist nicht umsetzbar?
          Aufgrund der aktuellen Lage bieten Ihnen unsere Private Offices mit separatem Zugang die Möglichkeit,
          ihre Projekte in ihrem Team erfolgreich weiterzuführen. </P>

        <h2> Frühstück</h2>
        <P> Entspanntes Frühstück im Büro? Unser nachhaltiges Frühstück kann gegen Voranmeldung hinzugebucht werden.</P>

        <h2> Kurierdienst</h2>
        <P>Ihre Ideen müssen schnell von A nach B? Unser hauseigener Kurierdienst tritt für Sie in die Pedale.</P>

        <h2> Rezeption</h2>
        <P>Sie erwarten Besuch? Unsere Rezeption ist von 6:00 bis 18:00 besetzt. Außerhalb dieser Uhrzeiten erreichen Sie uns über die Hotelklingel.</P>

        <h2> Übernachtung</h2>
        <P>Ihre Geschäftskunden brauchen eine Übernachtungsmöglichkeit?  In den angeschlossenen Vilhelm7 Berlin Residences reservieren wir gerne für Sie ein Zimmer.</P>


        <h2>Konferenzraum</h2>
        <P>Sie möchten Ihre Gäste professionell empfangen? Unser Konferenzraum bietet Platz für ca.15 Personen. Auf Wunsch decken wir auch für Sie ein.</P>

        <h2>Badezimmer</h2>
        <P>Eine Joggingrunde in der Mittagspause? Kein Problem.
          Jedes Private Office verfügt über ein eigenes, voll ausgestattetes Badezimmer.
          Die Sanitäranlagen benutzen nur Sie und Ihr Team. Frische Handtücher bekommen Sie ebenfalls von uns.</P>


        <h2>Desinfektionsservice</h2>
        <P>Gesund arbeitet es sich besser! Um besonders in Krisenzeiten den Hygienestandard hochzuhalten,
          bieten wir einen regelmäßigen Desinfektionsservice Ihrer Räumlichkeiten.</P>


        <h2>Reinigung</h2>
        <P>Eine regelmäßige, hygienische Grundreinigung der privaten und öffentlichen Bereiche erfolgt
          durch unser eingespieltes Reinigungsteam: Und Sie dürfen sich auf das konzentrieren,
          was wirklich zählt: Ihre Arbeit. Je nach Wunsch reinigen wir Ihre Zimmer auch täglich.</P>




        <h2>Postservice</h2>
        <P>Ihre Post nehmen wir gerne für Sie entgegen und verwahren sie an der Rezeption.</P>

<br/>
<br/>
        <h2>Unverbindliches Angebot</h2>
        <br/>

        <br/>
        <Flex>
          <OfferForm/>
          <Img src={img}></Img>
        </Flex>

      </Container>
    </Page>
  </IndexLayout>
)
}
}

export default Services
